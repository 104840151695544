import BillingService from '@/services/billing.service';
import DateHelper from '@/helpers/date.helper';

import Validations from "./Validations";

const service = new BillingService();

export default {
    name: 'invoices',
    props: [],
    data() {
        return {
            invoices: [],

            modals: {
                confirm: {
                    shown: false
                },

                paymentChange: {
                    shown: false
                },

                paidInvoice: {
                    shown: false
                }
            },

            perPage: 25,
            totalRows: 0,
            currentPage: 1,

            paymentSelected: {},
            filterPaid: '',
            searchVal: '',
            paidTypes: [{name: this.$t('paid'), value: true}, {name: this.$t('unpaid'), value: false}]
        }
    },

    validations: Validations,

    watch: {
        filterPaid(newVal) {
            if(newVal !== "") {
                this.searchVal = this.searchVal === "" ? `type=${newVal}` : this.filter(`type=${newVal}`);
                this.list();
            }
        },
    },
    computed: {
        owe() {
            return this.invoices.reduce((a, b) => a + (b.Amount || 0), 0);
        }
    },

    filters: {
        parseDate(value) {
            return  DateHelper.prettyDate(value)

        },
        capitalize (value) {
            return value.toUpperCase()
        },
        parsePaid(value) {
            if(value)
                return this.$t('paid')
            return this.$t('unpaid')
        }
    },

    async mounted() {
        this.list();
    },

    methods: {

        hideChangePayment() {
            this.modals.paymentChange.shown = false;
            this.paymentSelected = {};
        },

        hidePaidInvoice() {
            this.modals.paidInvoice.shown = false;
            this.paymentSelected = {};
        },

        hideConfirm() {
            this.modals.confirm.shown = false;
        },

        async list(page = 1) {
            this.currentPage = page;
            const skip = this.perPage * (this.currentPage - 1);

            const res = await service.listInvoices(this.perPage, skip, this.searchVal);
            if (res && !res.error) {
                this.totalRows = res.data.count;
                this.invoices = res.data.invoices;
            }
        },

        async next() {
            this.currentPage++;
            this.list();
        },

        async prev() {
            if (this.currentPage > 1)
                this.currentPage--;
            this.list();
        },

        async amountModify() {
            if(!this.$v.$invalid) {
                const res = await service.updateInvoiceAmount(this.paymentSelected);
                if(res && !res.error) {
                    this.paymentSelected = {};
                    this.$notify({
                        type: 'success',
                        message: this.$t('payment_success_amount')
                    });
                    await this.list();
                    this.hideChangePayment();
                } else {
                    this.$notify({
                        type: 'error',
                        message: this.$t('error_occur')
                    })
                }
            }
        },

        colorType(value) {
            switch (value) {
                case false:
                    return 'error';
                case true:
                    return 'success';
            }
        },

        filter(value) {
            let newFilter = "";
            if(this.searchVal.includes(value.split('=')[0])) {
                if(this.searchVal.includes('&')) {
                    let aux = this.searchVal.split('&');
                    for (let i in aux) {
                        if(typeof aux[i] === 'string' && aux[i].includes(value.split('=')[0])) {
                            aux[i] = value;
                        }
                    }

                    newFilter = aux.join('&');
                } else {
                    newFilter = value;
                }
            } else {
                newFilter = `${this.searchVal}&${value}`;
            }
            return newFilter;
        },

        resetFilter() {
            this.searchVal = "";
            this.list();
        },

        async paidInvoice() {
            const res = await service.paidInvoice(this.paymentSelected);
            if(res && !res.error) {
                this.paymentSelected = {};
                this.$notify({
                    type: 'success',
                    message: this.$t('payment_success_paid')
                });
                this.hideConfirm();
                this.hidePaidInvoice();
                this.list();
            } else {
                this.hideConfirm();
                this.$notify({
                    type: 'error',
                    message: this.$t('error_occur')
                })
            }
        }
    }
}
