import BillingService from '@/services/billing.service';
import DateHelper from '@/helpers/date.helper';
import config from "@/config";

const public_key = config[config.stage].stripe_key;

let stripe = undefined,
    elements = undefined;
let cardNumber = undefined,
    cardExpiry = undefined,
    cardCvc = undefined;

/*global Stripe*/
/*eslint no-undef: ["error", { "typeof": true }] */
if (typeof Stripe !== 'undefined') {
    stripe = Stripe(public_key);
    elements = stripe.elements();
}

const service = new BillingService();

export default {
    name: 'index',
    props: [],
    data() {
        return {
            unpaidInvoices: [],
            payments: [],
            credit: 0,
            cards: [],
            payMethods: [],

            modals: {
                addCard: {
                    shown: false,
                    card: {
                        name: '',
                        address_line1: '',
                        address_city: '',
                        address_state: '',
                        address_zip: '',
                        address_country: 'US'
                    }
                },

                deleteCard: {
                    shown: false,
                    card: null
                },

                paying: {
                    shown: false,
                    card: null
                }
            },

            validation: {
                error: false,
                errors: {},
            },

            perPage: 25,
            totalRows: 0,
            currentPage: 1,

            styles: {
                base: {
                    color: 'green',
                    fontWeight: 600,
                    fontFamily: 'Quicksand, Open Sans, Segoe UI, sans-serif',
                    fontSize: '16px',
                    fontSmoothing: 'antialiased',

                    ':focus': {
                        color: '#424770',
                    },

                    '::placeholder': {
                        color: '#9BACC8',
                    },

                    ':focus::placeholder': {
                        color: '#CFD7DF',
                    },
                },
                invalid: {
                    color: '#fff',
                    ':focus': {
                        color: '#FA755A',
                    },
                    '::placeholder': {
                        color: '#FFCCA5',
                    },
                },
            },

            classes: {
                focus: 'focus',
                empty: 'empty',
                invalid: 'invalid',
            }

        }
    },

    computed: {
        owe() {
            return this.unpaidInvoices.reduce((a, b) => a + (b.Amount || 0), 0);
        }
    },

    async mounted() {
        //Set up sales department
        if(this.$hasRole('root') && !this.$hasRole('reseller')) {
            this.$router.push({name: 'SettingBillingInvoices'});
        }

        await Promise.all([
            this.list(),
            this.getCredit(),
            this.getCards()
        ]);

        const res = await service.unpaidInvoices();
        if (res && !res.error) {
            this.unpaidInvoices = res.data.invoices;
        }
    },

    methods: {
        async showPaying() {
            if (this.cards.length === 0) {
                this.$router.push({name: 'SettingBillingPayment'});
            } else if (this.credit > this.owe) {
                const res = await service.payNow();
                if (res || !res.error) {
                    this.$router.go();
                } else {
                    this.$notify({
                        group: 'global',
                        type: 'error',
                        message: this.$t('error_paying_credits'),
                        duration: 3 * 1000
                    });
                }
            } else {
                this.modals.paying.shown = true;
                this.modals.paying.card = this.cards[0];
            }
        },

        hidePaying() {
            this.modals.paying.shown = false;
            this.modals.paying.card = null;
        },

        async pay() {
            if (this.modals.paying.card) {
                const res = await service.payNow(this.modals.paying.card.id, false);
                if (res || !res.error) {
                    this.$router.go();
                } else {
                    this.$notify({
                        group: 'global',
                        type: 'error',
                        message: this.$t('error_paying_cards'),
                        duration: 3 * 1000
                    });
                }
            }
        },

        showAddCard() {
            this.modals.addCard.shown = true;

            cardNumber = elements.create('cardNumber', {style: this.styles, classes: this.classes, showIcon: true});
            cardCvc = elements.create('cardCvc', {style: this.styles, classes: this.classes});
            cardExpiry = elements.create('cardExpiry', {style: this.styles, classes: this.classes});

            cardNumber.mount(this.$refs.cardNumber);
            cardCvc.mount(this.$refs.cardCvc);
            cardExpiry.mount(this.$refs.cardExpiry);
        },

        hideAddCard() {
            this.modals.addCard.shown = false;
            this.modals.addCard.card = {
                name: '',
                address_line1: '',
                address_city: '',
                address_state: '',
                address_zip: '',
                address_country: 'US'
            };
            cardNumber.destroy(this.$refs.cardNumber);
            cardCvc.destroy(this.$refs.cardCvc);
            cardExpiry.destroy(this.$refs.cardExpiry);
        },

        async createCard() {
            const result = await stripe.createToken(cardNumber, this.modals.addCard.card);
            const res = await service.createCard(result.token.id);

            if (res && !res.error) {
                this.getCards()
                this.hideAddCard();
                this.$notify({
                    group: 'global',
                    type: 'success',
                    message: this.$t('success_creating_card'),
                    duration: 3 * 1000
                });
            } else {
                this.$notify({
                    group: 'global',
                    type: 'error',
                    message: this.$t('error_creating_card'),
                    duration: 3 * 1000
                });
            }

        },

        showDeleteCard(card) {
            this.modals.deleteCard.shown = true;
            this.modals.deleteCard.card = card;
        },

        hideDeleteCard() {
            this.modals.deleteCard.shown = false;
            this.modals.deleteCard.card = null;
        },

        async deleteCard() {
            const res = await service.deleteCard(this.modals.deleteCard.card.id);
            if (res && !res.error) {
                this.getCards()
                this.hideDeleteCard();
                this.$notify({
                    group: 'global',
                    type: 'success',
                    message: this.$t('success_deleting_card'),
                    duration: 3 * 1000
                });
            } else {
                this.$notify({
                    group: 'global',
                    type: 'error',
                    message: this.$t('error_deleting_card'),
                    duration: 3 * 1000
                });
            }

        },

        getDate(dateStr, format) {
            return DateHelper.customFormat(dateStr, format);
        },

        async getCards() {
            const res = await service.cards(1000, 0);
            if (res && !res.error) {
                this.cards = res.data.cards.data ? res.data.cards.data : [];
            }
        },

        async list(page = 1) {
            this.currentPage = page;
            const skip = this.perPage * (this.currentPage - 1);

            const res = await service.paymentList(this.perPage, skip);
            if (res && !res.error) {
                this.payments = res.data.payments;
                this.totalRows = res.data.count;
            }
        },

        async getCredit() {
            const res = await service.credits();
            if (res && !res.error) {
                this.credit = res.data.credit;
            }
        },

        async next() {
            this.currentPage++;
            this.list();
        },

        async prev() {
            if (this.currentPage > 1)
                this.currentPage--;
            this.list();
        }
    },

    beforeDestroy() {
        if (cardNumber)
            cardNumber.destroy(this.$refs.cardNumber);
        if (cardCvc)
            cardCvc.destroy(this.$refs.cardCvc);
        if (cardExpiry)
            cardExpiry.destroy(this.$refs.cardExpiry);
    }
}
